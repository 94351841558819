import React, { useRef, useState, useLayoutEffect } from 'react'
import throttle from 'lodash/throttle'

import windowSize from '../hooks/windowSize'

import './FadeIn.scss'

const FadeIn = ({ children, className, id, duration, elementType }) => {
  const { innerHeight } = windowSize()
  const ref = useRef(null)
  const [variable, setVariable] = useState(false)

  const animateEl = () => {
    const refPos = ref.current.getBoundingClientRect().top
    const viewPos = refPos / innerHeight
    if (viewPos < 1 && variable === false) {
      setVariable(true)
    }
  }

  const handleAnimateEl = throttle(animateEl, 100)

  useLayoutEffect(animateEl)

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleAnimateEl, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleAnimateEl, { passive: true })
    }
  }, [handleAnimateEl])

  const ElementType = elementType

  return (
    <>
      <ElementType
        className={'fade-in ' + (className ? className : '')}
        id={id ? id : ''}
        ref={ref}
        style={{
          opacity: variable ? 1 : 0,
          transition: `opacity ${duration + 0.25}s ${duration / 4}s ease-out`,
        }}
      >
        <div
          className="fade-in-content"
          style={{
            transform: variable
              ? 'translate3D(0,0,0)'
              : 'translate3d(0,3rem,0)',
            transition: `transform ${duration + 0.5}s ${duration / 8}s ease`,
          }}
        >
          {children}
        </div>
      </ElementType>
    </>
  )
}

FadeIn.defaultProps = {
  elementType: 'div',
  duration: 1,
}

export default FadeIn
