import { useState, useEffect } from 'react'
import debounce from 'lodash/debounce'

const getSize = () => {
  return {
    innerHeight: typeof window !== 'undefined' && window.innerHeight,
    innerWidth: typeof window !== 'undefined' && window.innerWidth,
    outerHeight: typeof window !== 'undefined' && window.outerHeight,
    outerWidth: typeof window !== 'undefined' && window.outerWidth,
  }
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getSize())

  useEffect(() => {
    const listener = () => {
      setWindowSize(getSize())
    }
    const handler = debounce(listener, 50, true)
    window.addEventListener('resize', handler)
    return () => {
      window.removeEventListener('resize', handler)
    }
  })

  return windowSize
}

export default useWindowSize
