import React, { useRef, useEffect, useState, useLayoutEffect } from 'react'
import throttle from 'lodash/throttle'

import windowSize from '../hooks/windowSize'

import FadeIn from './FadeIn'

import './Divider.scss'

const Divider = ({ style, className }) => {
  const { innerHeight } = windowSize()

  const dividerRef = useRef(null)

  const [variable, setVariable] = useState(0)

  const animateEl = () => {
    const refPos = dividerRef.current.getBoundingClientRect().top
    const refHeight = dividerRef.current.getBoundingClientRect().height

    const viewPos = (refPos + refHeight / 2) / innerHeight

    if (viewPos < 1.2 && viewPos > -0.2) {
      window.requestAnimationFrame(() => {
        setVariable(1 - viewPos)
      })
    }
  }

  const handleAnimateEl = throttle(animateEl, 10)

  useEffect(animateEl)

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleAnimateEl)
    return () => {
      window.removeEventListener('scroll', handleAnimateEl)
    }
  }, [handleAnimateEl])

  return (
    <FadeIn duration={0.5}>
      <div
        className={'divider ' + (className ? className : '')}
        ref={dividerRef}
        style={style}
      >
        <span
          style={{
            transform: `rotateX(${60 - variable * 360}deg) rotateZ(45deg)`,
          }}
        >
          <span />
          <span />
          <span />
          <span />
        </span>
        <span
          style={{
            marginLeft: `${1 + variable * 2.5}rem`,
            marginRight: `${1 + variable * 2.5}rem`,
            transform: `rotateX(${45 + variable * 270}deg) rotateZ(45deg)`,
          }}
        >
          <span />
          <span />
          <span />
          <span />
        </span>
        <span
          style={{
            transform: `rotateX(${60 + variable * 360}deg) rotateZ(45deg)`,
          }}
        >
          <span />
          <span />
          <span />
          <span />
        </span>
      </div>
    </FadeIn>
  )
}

export default Divider
